#portfolio-wrapper .columns {
    margin-bottom: 36px;
    width: 50%;
}

.column, .columns {
    position: relative;
    /* padding: 0 20px; */
    border-radius: 2%;
    min-height: 1px;
    display: inline-block;
}

.portfolio-item .item-wrap {
    background: #fff;
    overflow: hidden;
    position: relative;
    height: 500px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.portfolio-item .item-wrap h5, p {
    margin: 8px;
    font-size: 20px;
    line-height: 30px;
}

.portfolio-item .item-wrap a {
    display: block;
    cursor: pointer;
    color: #000;
    height: 100%;
}

.portfolio-item .item-wrap .image {
    vertical-align: bottom;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: 100% auto;
}